<template>
  <div>
    <banner title="Feature" />
    <div align="center" class="my-10">
      <v-card max-width="800" class="mt-4 mb-4" elevation="5">
        <v-card-title id="top">
          <v-img height="400" contain :src="features.image" class="my-4" :alt="features.slug"></v-img>
        </v-card-title>
        <div id="chicken" class="py-4" v-html="features.content"></div>
        <div v-for="l in features.link" :key="l.id">
          <v-btn dark color="blue lighten-2" :href="l.link" target="_blank" elevation="4" class="my-4">Click here to
            recieve your copy!</v-btn>
        </div>
        <v-row justify="center" v-if="features.artist">
          <v-col align-self="center" align="center">
            <v-card-actions v-if="features.feature_type === 'ARTIST'">
              <v-btn :to="features.requesturl" color="primary" style="margin: 0 auto">Request {{ features.artist }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <!-- <div v-else>
      <v-dialog v-model="featureDialog" width="500" persistent>
        <v-card>
          <v-card-text class="text-h5 text-center">No Feature Today</v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn to="/" dark color="primary"
            @click="closeFeatureDialog">Return to Home Page</v-btn>
          <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>-->
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";

export default {
  name: "Features",
  components: { Banner },
  data: () => ({}),
  metaInfo() {
    return {
      title: "Feature Page",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            `${this.features.content}`
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "At GraceWay Radio we feature limited time content from ministries that we support."
        },
        { vmid: "og:title", property: "og:title", content: `${this.features.content}` },
        { vmid: "robots", name: "robots", content: "index,noarchive" },
        {
          vmid: "og:url",
          property: "og:url",
          content: "https://gracewayradio.com/feature"
        }
      ]
    };
  },
  computed: {
    featureDialog() {
      return this.$store.state.featureObjects?.featureDialog;
    },
    features() {
      return this.$store.state.featureObjects?.features;
    },

  },
  methods: {
    closeFeatureDialog() {
      this.$store?.dispatch("featureObjects/toggleFeatureDialog", false);
    }
  },
  mounted() {
    this.$store.dispatch("featureObjects/getFeatures");
  }
};
</script>

<style lang="scss" scoped>
#chicken {
  text-justify: newspaper !important;
  text-align: left !important;
  text-indent: 2rem !important;
  margin: 0 2rem !important;
}

#top {
  background-image: url("../assets/blue.jpg");
  background-size: 90% 100%;
  background-position: center center;
}
</style>
